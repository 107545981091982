<template>
  <div class="item_box">
    <div class="top_right">{{ $t("bh_Num") }}{{ item.number }}</div>
    <div class="top" @click="companyDialog">
      <div class="top_t">
        <img :src="item.logo ? item.logo : defaultLogo" alt=""/>
        <div class="top_t_r">
          <div class="title">
            <span>{{
              item.company_name_en | priorFormat(item.company_name_zh, LOCALE)
            }}</span>
            <i
              v-if="item.video_list.length != 0"
              @click.stop="playVideo(item.video_list[0])"
              class="icon iconfont iconplay"
            ></i>
          </div>
          <div class="address">
            <p class="text_overflow_2">
              <span>{{
                item.country
                  | countryCityFormat(item.region_en, item.region_zh, LOCALE)
              }}</span>
            </p>
            <p>
              <span>{{ $t("Booth") }}：{{ item.booth_no | textFormat }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="top_b">
        <h1>{{ navValue.lable_type }}：</h1>
        <div v-if="navValue.search_type == 2" class="text_overflow_2">
          {{ item.extend | textFormat }}
        </div>
        <div v-if="navValue.search_type == 1" class="text_overflow_2">
          {{ item.profile_en | priorFormat(item.profile_zh, LOCALE) }}
        </div>
        <!-- <span v-if="item.profile_en&&item.profile_en.length>80 || item.profile_en&&item.profile_zh.length>60" class="more">...{{$t('more')}}</span> -->
      </div>
    </div>
    <div class="bottom">
      <div @click="qrCode">
        <img v-if="item.qr_code_url" :src="item.qr_code_url" alt="" />
      </div>
      <voteBtn
        :number="item.votes"
        :index="index"
        :vote_type="navValue.search_type"
        :voted_id="item.company_id"
      ></voteBtn>
    </div>
    <videoPlay ref="allvideoplay"></videoPlay>
  </div>
</template>
<script>
import voteBtn from "~exp/components/rankingList/voteBtn";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  props: ["item", "navValue", "index"],
  components: {
    voteBtn,
    videoPlay,
  },
  data() {
    return {
      defaultLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/logo.png",
    };
  },

  mounted() {},
  destroyed() {},
  methods: {
    //打开公司详情
    companyDialog() {
      this.$GLOBALEVENT.$emit("OPENCOMPANY", {
        show: true,
        params: this.item,
        type: this.navValue,
        index: this.index,
      });
    },
    // 打开二维码弹框
    qrCode() {
      return;
      this.$GLOBALEVENT.$emit("OPENQRCODE", { show: true, params: this.item });
    },
    playVideo(row) {
      // let id=`033b4d7b029347469fcd0dbc48f2090b`
      this.$refs.allvideoplay.startPlay(row.aliyun_id);
    },
  },
};
</script>
<style lang="less" scoped>
.item_box {
  width: 420px;
  height: 250px;
  background: #ffffff;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 16px;
  padding: 16px;
  padding-top: 28px;
  position: relative;
  .top_right {
    cursor: pointer;
    width: 106px;
    height: 26px;
    background: linear-gradient(
      270deg,
      #fffada 0%,
      rgba(255, 252, 231, 0) 100%
    );
    position: absolute;
    right: 0px;
    top: 0;
    font-size: 12px;
    font-weight: 800;
    color: #ff9905;
    line-height: 26px;
    text-align: right;
    padding-right: 13px;
  }
  .top {
    .top_t {
      display: flex;
      img {
        cursor: pointer;
        width: 72px;
        height: 72px;
      }
      .top_t_r {
        padding-left: 16px;
        padding-top: 13px;
        padding-bottom: 9px;
        .title {
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            display: inline-block;
            margin-right: 8px;
            max-width: 224px;
            height: 22px;
            font-size: 16px;
            font-weight: 800;
            color: #333333;
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis;
          }
          i {
            width: 16px;
            height: 16px;
            font-size: 16px;
            color: #b7daff;
          }
          i:hover {
            color: #027fff;
          }
        }
        .address {
          padding-top: 8px;
          display: flex;
          width: 300px;
          height: 48px;
          p:first-child {
            font-size: 14px;
            font-weight: 400;
            color: #7d8695;
            max-width: 195px;
            min-width: 63px;
            overflow: hidden;
          }
          p:nth-child(2) {
            padding-left: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #dd9740;
            min-width: 75px;
            text-align: right;
          }
        }
      }
    }
    .top_b {
      // padding-top: 12px;
      position: relative;
      h1 {
        font-size: 12px;
        font-weight: 400;
        color: #1f292e;
        line-height: 22px;
      }
      div {
        color: #6a6a6a;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        height: 43px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .more {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        color: #027fff;
        font-size: 12px;
        font-weight: 400;
        bottom: 0px;
        right: 0;
        background-color: #fff;
      }
    }
  }
  .bottom {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    div:first-child {
      width: 36px;
      height: 36px;
      // background: #F2F2F2;
      font-size: 12px;
      line-height: 36px;
      cursor: pointer;
    }
    div:nth-child(2) {
      cursor: pointer;
      width: 119px;
      height: 30px;
      line-height: 30px;
      padding: 0 18px;
      background: linear-gradient(180deg, #fdd689 0%, #ff9905 100%);
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 14px;
      font-weight: 800;
    }
  }
}
.dialogBox {
  /deep/ .el-dialog {
    box-shadow: none;
  }
  /deep/ .el-dialog__body {
    padding-bottom: 0px;
  }
  /deep/ .el-dialog__header {
    height: 0;
  }
  .dialog_box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 108px;
      height: 108px;
      margin-top: 27px;
      margin-bottom: 12px;
    }
    h1 {
      width: 224px;
      height: 22px;
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      line-height: 22px;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .address {
      padding-top: 8px;
      display: flex;
      p:first-child {
        font-size: 14px;
        font-weight: 400;
        color: #7d8695;
      }
      p:nth-child(2) {
        padding-left: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #dd9740;
      }
    }
    .erweiCode {
      width: 180px;
      height: 180px;
      background: #f2f2f2;
      text-align: center;
      line-height: 180px;
      margin-top: 16px;
      margin-bottom: 8px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #1f292e;
        line-height: 17px;
      }
    }
    .lable {
      font-size: 12px;
      font-weight: 400;
      color: #7d8695;
      line-height: 17px;
    }
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d8d8d8;
    padding-top: 0px;
    padding-bottom: 3px;

    height: 73px;
    i {
      cursor: pointer;
      display: inline-block;
      width: 16px;
      height: 16px;
      color: #7d8695;
      border: 1px solid #7d8695;
      border-radius: 10px;
      margin-right: 8px;
    }
    span {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      color: #1f292e;
    }
  }
}
</style>