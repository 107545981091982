<template>
    <div class="c_peoplebox">
        <div class="top_right" @click="qrCode">
            <span>{{$t('bh_Num')}}{{item.number}}</span>
            <!-- <div>
                <div  v-if="!item.qr_code_url">{{$t('erweiCode')}}</div>
                <img v-if="item.qr_code_url" :src="item.qr_code_url" alt="">
            </div> -->
        </div>
        <img @click="companyDialog" :src="!item.avatar?DEFAULT_AVATAR:item.avatar" alt="" style="border-radius: 50%">
        <div  @click="companyDialog"  class="name_zw">
            <span>{{item.user_name_en|priorFormat(item.user_name_zh,LOCALE)}}</span>
            <span>{{ item.job_title_en | priorFormat(item.job_title_zh,LOCALE) }}</span>
        </div>
        <h1  @click="companyDialog"  class="company_name textOverflow">{{item.company_name_en|priorFormat(item.company_name_zh,LOCALE)}}</h1>
        <div  @click="companyDialog"  class="kysj">
            <div>
                <h1>{{navValue.lable_type}}：</h1>
                <i v-if="item.video_list.length!=0" @click.stop="playVideo(item.video_list[0])" class="icon iconfont iconplay"></i>
            </div>
            <p class="two_Line">{{ item.extend|textFormat }}</p>
        </div>
        <div>
            <voteBtn :number='item.votes' :index='index' :vote_type="navValue.search_type" :voted_id="item.user_id" ></voteBtn>
        </div>
        <videoPlay ref="allvideoplay" />
    </div>
</template>
<script>
import voteBtn from '~exp/components/rankingList/voteBtn'
import videoPlay from '@/baseComponents/videoPlay'
export default {
    props:{
        item:{
            type:Object,
            default:function(){
                return {}
            }
        },
        navValue:{
            type:Object,
            default:function(){
                return {}
            } 
        },
        index:{
            type:Number | String,
        }
    },
    components:{
        voteBtn,videoPlay
    },
    data(){
        return{
            
        }
    },
    methods:{
         //打开人员详情
        companyDialog(){
            this.$GLOBALEVENT.$emit("OPENCOMPANY",{show:true,params:this.item,type:this.navValue,index:this.index})
        },
        // 打开二维码弹框
        qrCode(){
            return
            
        },
        playVideo(row){
            console.log('row.aliyun_idrow.aliyun_id',row.aliyun_id);
            this.$refs.allvideoplay.startPlay(row.aliyun_id)
        }
    },
    
}
</script>
<style lang="less" scoped>
    .c_peoplebox{
        width: 420px;
        height: 364px;
        background: #FFFFFF;
        padding: 32px 18px;
        padding-top: 28px;
        border-radius: 10px;
        margin-right:20px ;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .top_right{
            cursor: pointer;
            position: absolute;
            width: 77px;
            height: 106px;
            background: linear-gradient(180deg, #FFFADA 0%, rgba(255, 252, 231, 0) 100%);
            border-radius: 0px 10px 0px 0px;
            top:0px;
            right:0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            span{
                margin-top: 5px;
                padding-bottom: 10px;
                font-size: 14px;
                font-weight: 800;
                color: #FF9905;
            }
            div{
                width: 36px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: #F2F2F2;
                font-size: 1px
            }
        }

        img{
            cursor: pointer;
            width: 92px;
            height: 92px;
        }
        .name_zw{
            cursor: pointer;
            margin-top: 16px;
            margin-bottom: 12px;
            text-align: center;
            display: flex;
            align-items: center;
            span{
                overflow: hidden;
                white-space: nowrap !important;
                text-overflow: ellipsis;
            }
            span:first-child{
                display: inline-block;
                font-size: 18px;
                font-weight: 800;
                color: #333333;
                padding-right: 8px;
                max-width: 200px;
                text-align: right;
            }
            span:nth-child(2){
                
                font-size: 14px;
                font-weight: 400;
                color: #7D8695;
                max-width: 100px;
                text-align: left;
            }
        }
        .company_name{
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            text-align: center;
            width: 100%;
            height: 26px;
        }
        .kysj{
            cursor: pointer;
            position: relative;
            margin-top:24px;
            margin-bottom:20px;
            div:first-child{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom:8px ;
                h1{
                    height: 16px;
                    line-height: 16px;
                }
                i{
                    width: 16px;
                    height: 16px;
                    color: #B7DAFF;
                }
                i:hover{
                    color: #027FFF;
                }
            }
            .two_Line{
                width: 388px;
                color: #6A6A6A;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                cursor: pointer;
                height: 43px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal; 

            }
            .more{
                cursor: pointer;
                position: absolute;
                background: #FFFFFF;
                display: inline-block;
                right: 5px;
                width: 57px;
                padding-left: 5px;
                bottom: 2px;
                text-align: right;
                color: #027FFF;
            }
        }
    }
</style>