<template>
  <div class="search" v-if="myClean">
    <el-autocomplete
        class="inline-input"
        v-model="name"
        :fetch-suggestions="querySearchCompanyName"
        :placeholder="placeTitle"
        :trigger-on-focus="false"
        @select="handleCompanyId"
    >
    
     <el-select v-model="query_type" @change="select_change" slot="prepend" class="eacrch_select" >
      <el-option class="textOverflow" :label="$t('dylancompanyName')" value="1"></el-option>
      <el-option :label="$t('name')" v-if="parent_query.query.type!='company'" value="2"></el-option>
      <el-option :label="$t('number_bh')" value="3"></el-option>
    </el-select>
      <template slot-scope="{ item }">
        <div class="name" :value="item.id">{{ item.value }}</div>
      </template>
    </el-autocomplete>




    <el-button @click="search" class="btn" type="primary" icon="el-icon-search"></el-button>
  </div>








</template>

<script>
export default {
  name: "searchCompany",
  props: {
    value:{
      type:String
    },
    index: {
      type: Number
    },
    parent_query:{
      type:Object,
      default:function(){
        return {
         
        }
      }
    }
  },
  data(){
    return {
      myClean:true,
      watch_apply_type:"",
      query_type:"1",
      name:this.value,
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      placeTitle:this.$t('pleaseinputcompany'),
      search_option_values:[]
    }
  },
  mounted(){
  },
  watch: {
    name(nv) {
      this.$emit("input", nv);
      if (nv === "") {
        this.$emit("pitch", "");
      }
    },
    value(nv) {
      this.name = nv;
    },
    parent_query:function(value){
      //query_type 1-公司名2-人名
      //apply_type 报名类型: 1-抗疫英雄，2-物流型男，3-物流女神
      this.myClean=false
      this.search_option_values=[]
      this.query_type="1"
      this.placeTitle=this.$t('pleaseinputcompany')
      this.watch_apply_type=value.lenovo

      setTimeout(()=>{
        this.myClean=true
      },800)

    }
  },
  mounted() {

  },
  methods: {
    // select 变化  palceholder也变化
    select_change(e){
      this.myClean=false
      if(e==1){
          this.placeTitle=this.$t('pleaseinputcompany')
      }
      if(e==2){
          this.placeTitle=this.$t('user_name')
      }
      if(e==3){
          this.placeTitle=this.$t('search_num')
      }
      setTimeout(()=>{
        this.myClean=true
      },100)
    },
    async querySearchCompanyName(queryString,cb){

      let params={
        name:this.name,
        query_type:this.query_type,
        apply_type:this.parent_query.lenovo
      }
      if(this.query_type==3||this.name==""){

        console.log('>>>>>>>>>>>>>>');
        cb([])
        return
      }
      let res;
      this.search_option_values=[]
      if( this.parent_query.query.type=='company' ){   //企业联想
          params.apply_type=this.parent_query.query.value
          params.nopage=1
          if(params.query_type==1){
            params.company_name=this.name
          }else{
            params.number=this.name
          }


          params.query_type=null
          params.name=null
          res = await this.$store.dispatch('register/vote_company_applyList', params)

          if (this.zhReg.test(queryString)) {
            this.search_option_values = res.data.map(item => {
              item.value = item.company_name_zh;
              item.id = item.id;
              return item;
            });
          } else {
            // 输入是英文或者是数字
            this.search_option_values = res.data.map(item => {
              if(this.LOCALE=='en'){
                if (item.company_name_en) {
                  // 返回数据中，英文有值
                  item.value = item.company_name_en;
                  item.id = item.id;
                } else {
                  item.value = item.company_name_zh;
                  item.id = item.id;
                }
              }else{
                  if (item.company_name_zh) {
                    // 返回数据中，英文有值
                    item.value = item.company_name_zh;
                    item.id = item.id;
                  } else {
                    item.value = item.company_name_en;
                    item.id = item.id;
                  }
              }
              return item;
            });
          }



      }else{  // 人员联想
          res =await this.$store.dispatch("register/search_user_company",params)
          if (this.zhReg.test(queryString)) {
          this.search_option_values = res.data.map(item => {
            item.value = item.name_zh;
            item.id = item.id;
            return item;
          });
      } else {
        // 输入是英文或者是数字
        this.search_option_values = res.data.map(item => {
          if(this.LOCALE=='en'){
            if (item.name_en) {
              // 返回数据中，英文有值
              item.value = item.name_en;
              item.id = item.id;
            } else {
              item.value = item.name_zh;
              item.id = item.id;
            }
          }else{
               if (item.name_zh) {
                // 返回数据中，英文有值
                item.value = item.name_zh;
                item.id = item.id;
              } else {
                item.value = item.name_en;
                item.id = item.id;
              }
          }
          return item;
        });
      }
      }
      
      
      cb(this.search_option_values)
    },
    handleCompanyId(item){
      if (this.index !== undefined) {
        this.$emit("pitch", {
          item: item,
          index: this.index
        });
      } else {
        this.$emit("pitch", item);
      }
    },
    search(){
      let _type="name"
      if(this.query_type==3){
        _type="number"
      }
      this.$emit('search',{type:_type,query_type:this.query_type})
    }
  }
}
</script>

<style scoped lang="less">
.search{
  .inline-input{
    width: 400px;
    position:relative;
    right:-5px;
  }
  .btn{
    position:relative;
    top: -1px;
    z-index: 5;
    border-radius:0 4px 4px 0;
  }
  .eacrch_select{
    width: 130px;
  }
}
.name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
