<template>
    <div style="background: #F6F7F9;">
        <banner></banner>
        <div class="content_area">
            <rankingNav class="navStyle" :loading='loading' :navlist='navlist' :navlist_active='type_id' width="1300px" @navEvents='navEvents' ></rankingNav>
            <div class="search_box">
                <h1 @click="topangbang">{{$t('ViewLeaderboard')}}</h1>
                <search v-model="searchParams.name" :parent_query='navValue'  @search="search" class="search-wrap"></search>
            </div>
            <div  v-loading="loading" style="min-height:500px;position:relative;" >
                <div class="itemsBox" v-if="type_id==1 || type_id==3">
                    <listCompany v-for="(item,i) in companyList" :key='i' :class="{isRight:(i+1)%3==0}" :item='item' :index='i' :navValue='navValue' ></listCompany>
                </div>
                <div class="itemsBox" v-else>
                    <listPeople v-for="(item,i) in peopleList" :key='i' :class="{isRight:(i+1)%3==0}" :item='item' :index='i'  :navValue='navValue' ></listPeople>
                </div>
                <noDataImg v-if="!loading && navValue.query.type=='company'&&companyList.length==0 || !loading && navValue.query.type=='people'&&peopleList.length==0"></noDataImg>
            </div>
        </div>
            <el-pagination class="content_area exp_pagination" style="text-align:right;padding-bottom: 50px;"
                layout="prev, pager, next"
                :page-size='limit'
                :current-page.sync='start'
                :total="total">
            </el-pagination>
        <qrcode></qrcode>
        <dialogCompany :navValue='navValue'  ></dialogCompany>
    </div>
</template>
<script>
import "@/baseAssets/icon/iconfont.css";
import banner from '~exp/components/rankingList/banner'
import rankingNav from '~exp/components/rankingList/rankingNav'
import search from '~exp/components/common/search'
import listCompany from '~exp/components/rankingList/listCompany'
import listPeople from '~exp/components/rankingList/listPeople' 
import dialogCompany from '~exp/components/rankingList/dialog_company'
import qrcode from '~exp/components/rankingList/QRcode'

export default {
    components:{
        banner,
        rankingNav,
        search,
        listCompany,
        listPeople,
        dialogCompany,
        qrcode
    },
    data(){
        return{
            list_index:0,
            type_id:3,
            navValue:{
                lable:this.$t("logisticscompanies"),//"物流人气企业",
                    id:"3",
                    search_type:1,
                    lable_type:this.$t('companyDesc'), //企业简介
                    lenovo:"5",
                    query:{
                        type:"company",
                        value:1,
                    }
            },
            loading:false,
            navlist_active:1,
            s_name:"",
            s_number:"",
            s_query_type:"2",
            total:0,
            start:0,
            limit:15,
            navlist:[  
                    //1-物流人气企业，2-抗疫贡献企业，3-抗疫英雄，4-物流型男，5-物流女神 VotingWillOpenOnMarch29
                    // 报名类型: 1-物流人气企业，2-抗疫贡献企业  
                    //报名类型: 1-抗疫英雄，2-物流型男，3-物流女神
                {
                    lable:this.$t("logisticscompanies"),//"物流人气企业",
                    id:"3",
                    search_type:1,
                    lable_type:this.$t('companyDesc'), //企业简介
                    lenovo:"5",
                    query:{
                        type:"company",
                        value:1,
                    }
                },
                {
                    lable:this.$t('contributionenterprises'),//"抗疫贡献企业", this.$t("")
                    id:"1",
                    search_type:2,
                    lable_type:this.$t('Protest'),//抗议事迹
                    lenovo:"4",
                    query:{
                        type:"company",
                        value:2,
                    }
                },
                {
                    lable:this.$t("epidemichero"),//"抗疫英雄",
                    id:"2",
                    search_type:3,
                    lable_type:this.$t('Protest'),//抗议事迹
                    lenovo:"1",
                    query:{
                        type:"people",
                        value:1,
                    }
                },
                {
                    lable:this.$t("Logisticsbeauty"),//"物流女神",
                    id:"4",
                    search_type:5,
                    lable_type:this.$t('Personal_profile'),
                    lenovo:"3",
                    query:{
                        type:"people",
                        value:3,
                    }
                },
                {
                    lable:this.$t("Logisticsman"),//"物流型男",
                    search_type:4,
                    lable_type:this.$t('Personal_profile'),
                    id:"5",
                    lenovo:"2",
                    query:{
                        type:"people",
                        value:2,
                    }
                },
                
            ],
            searchParams:{
                name:""
            },
            companyList:[],
            peopleList:[]
        }
    },
    watch:{
        start(value){
            if(this.navValue.query.type=="people"){
                this.getPeopelList(this.navValue.search_type)
            }else{
                this.getCompanyList({apply_type:this.navValue.search_type})
            }
        }
    },
    mounted(){
        // try {
        //     this.type_id=this._decode(
        //         this.$route.query.parameter
        //     ).type
        // } catch (error) {
        // }

        // listCompany  listPeople 投票成功--——>跟新列表页
        this.$GLOBALEVENT.$on("VOTESUCCESS",(row)=>{

            if(this.companyList.length!=0){
                this.companyList[row.index].votes=row.votes
            }
            if(this.peopleList.length!=0){
                this.peopleList[row.index].votes=row.votes
            }
        })
    },
    destroyed(){
        this.$GLOBALEVENT.$off("VOTESUCCESS")
    },
    methods:{
        //获取公司报名列表
        async getCompanyList(row) {
            this.loading = true
            let params = {}
            params.apply_type=row.apply_type
            params.start=this.start-1
            params.limit=this.limit
            params.number=this.s_number
            params.company_name=this.s_name
            params.meeting_id=this.MEETING_ID
            
            let res = await this.$store.dispatch('register/vote_company_applyList', params)
           
            if (res.success) {
                this.loading = false
                this.companyList=res.data
                this.peopleList=[]
                this.total=res.total
            } else {
                this.loading = false
            }
        },
        //获取人员列表
        async getPeopelList(type_id) {
            this.loading = true
            let params = {}
            params.apply_type=this.navValue.search_type
            params.start=this.start-1
            params.limit=this.limit
            params.number=this.s_number
            params.name=this.s_name
            params.query_type=this.s_query_type
            //编号搜索时  params.query_type=""
            if(this.s_number=='' || !this.s_number){
                if(this.s_name==""){
                    params.query_type=""
                }
                
            }
            let res = await this.$store.dispatch('register/vote_user_applyList', params)
            
            if (res && res.success) {
                this.loading = false
                this.peopleList = res.data
                this.companyList=[]
                this.total=res.total
            }else{
                this.loading = false
            }
        },
        // 每次切换 清空params
        navEvents(row){
            this.navValue=row
            this.type_id=row.id
            this.start=1
            this.s_name=""
            this.s_number=""
            this.searchParams.name=""
            this.s_query_type=null
            if(row.query.type=="people"){
                this.getPeopelList(row.search_type)
            }else{
                this.getCompanyList({apply_type:row.search_type})
            }
        },
        search(row){
            if(row.type=='name'){
                this.s_name=this.searchParams.name
                this.s_query_type=row.query_type
                this.s_number=""
            }else{
                this.s_name=""
                this.s_number=this.searchParams.name
                this.s_query_type=null
            }
            this.start=1
            if(this.navValue.query.type=="people"){
                this.getPeopelList(this.navValue.query.value)
            }else{
                this.getCompanyList({apply_type:this.navValue.query.value})
            }
        },
        //
        topangbang(){
            let data=this.$moment('2021-3-29').startOf('day').unix()
            if (this.$moment().unix() < data){
                this.$message({
                    message: this.$t('VotingWillOpenOnMarch29'),
                    type: 'warning'
                });
                return
            }


            this.$router.push({
                path:'/overallRankingDetail',
                query:{
                    parameter:this._encode({
                        rank_id:this.type_id
                    })
                }
            })
        }
    }    
}
</script>
<style lang="less" scoped>
    
    .navStyle{
        margin-top: 30px;
    }
    .search_box{
        padding-top: 36px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            font-size: 24px;
            font-weight: bold;
            color: #666666;
            cursor: pointer;
        }
    }
    .itemsBox{
        display: flex;
        flex-wrap: wrap;
        .isRight{
            margin-right:0 ;
        }
    }
</style>