<template>
    <!-- 弹框 企业信息 -->
    <el-dialog
        class="dialogBox"
        :visible.sync="dialogVisible_company"
        width="630px"
        height="547px"
        center
    >
        <div class="dialog_box">
            <img class="logo" :src="item.logo?item.logo:default_header" alt="">
            <h1>{{item.company_name_en | priorFormat(item.company_name_zh)}}</h1>
            <div class="address">
                <p>
                    <span>{{item.company_name_en | priorFormat(item.company_name_zh)}}</span>
                    <span>·</span>
                    <span>{{ item.city }}</span>
                </p>
                <p>
                    <span>展位：{{item.zhanwei}}</span>
                </p>
            </div>
            <div class="erweiCode">
                <span v-if="item.erweima==''">{{$t('erweiCode')}}</span>
            </div>
            <span class="lable">{{$t('ScanAndShare')}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
            <i class="el-icon-back" @click="dialogVisible_company=false"></i> 
            <span @click="dialogVisible_company=false">返回上一级</span>
        </span>
    </el-dialog>
</template>
<script>
export default {
    // props:{
    //     item:{
    //         type:Object,
    //         default: function () {
    //             return {
    //                 num:"15",
    //                 logo:require('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/logo.png'),
    //                 video:"",
    //                 company:"链韵物流科技（上海）有限公司",
    //                 country:"中国",
    //                 city:"上海",
    //                 zhanwei:"H-102",
    //                 content:'疫情爆发时，湖南海南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，在总经理的带领下，员工纷纷为武汉筹资募捐。面对来势汹汹的疫情爆发时，湖南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，在总经理的带领下，员工纷纷为武汉筹资募捐。面对来势汹汹的疫情爆发时，湖南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，在总经理的带领下，员工纷纷为武汉筹资募捐。面对来势汹汹的疫情爆发时，湖南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，在总经理的带领下，员工纷纷为武汉筹资募捐。面对来势汹汹的疫情爆发时，湖南海泰克诺与国共渡难关！国家有难，匹夫有责，除积极复工生产外，在总经理的带领下，员工纷纷为武汉筹资募捐。面对来势汹汹的',
    //                 erweima:"",
    //                 heart:2025103,
    //             }
    //         }
    //     }
    // },
    data(){
        return{
            item:{},
            dialogVisible_company:false,
            default_header:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/head.png'
        }
    },
    mounted(){
        this.$GLOBALEVENT.$on("OPENQRCODE", (row) => {
            
            // this.getPeopleDetail(row)
            this.dialogVisible_company=row.show
        });
    },
    destroyed(){
        this.$GLOBALEVENT.$off("OPENQRCODE")
    },
    methods:{
        async getPeopleDetail(row){
            let res = await this.$store.dispatch('register/vote_user_apply_detail', {apply_id:row.params.id})
          
            this.item=res.data
        }
    }
}
</script>
<style lang="less" scoped>
    .dialogBox{
        /deep/ .el-dialog {
            box-shadow:none
        }
        /deep/ .el-dialog__body{
            padding-bottom: 0px;
        }   
        /deep/ .el-dialog__header{
            height: 0;
        }
        .dialog_box{
            position: relative;
            display:flex;
            flex-direction: column;
            align-items: center;
            .logo{
                width: 108px;
                height: 108px;
                margin-top:27px ;
                margin-bottom:12px ;
            }
            h1{
                width: 224px;
                height: 22px;
                font-size: 16px;
                font-weight: 800;
                color: #333333;
                line-height: 22px;
                overflow: hidden;
                white-space: nowrap !important;
                text-overflow: ellipsis;
            }
            .address{
                padding-top:8px;
                display: flex;
                p:first-child{                   
                    font-size: 14px;
                    font-weight: 400;
                    color: #7D8695;
                }
                p:nth-child(2){    
                    padding-left: 24px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #DD9740;
                }
            }
            .erweiCode{
                width: 180px;
                height: 180px;
                background: #F2F2F2;
                text-align: center;
                line-height: 180px;
                margin-top: 16px;
                margin-bottom: 8px;
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color: #1F292E;
                    line-height: 17px;
                }
            }
            .lable{
                font-size: 12px;
                font-weight: 400;
                color: #7D8695;
                line-height: 17px;
            }
        }
        .dialog-footer{
            
            display: flex;
            align-items: center;
            justify-content: center;
            border-top:1px solid #D8D8D8 ;
            padding-top:0px;
            padding-bottom:3px;
            
            height: 73px;
            i{
                cursor: pointer;
                display: inline-block;
                width: 16px;
                height: 16px;
                color: #7D8695;
                border:1px solid  #7D8695;
                border-radius:10px ;
                margin-right: 8px;
            }
            span{
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                color: #1F292E;
            }
            
        }
    }
</style>